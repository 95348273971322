.container-navbar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  /*overflow: hidden;*/
  height: 3.5em;
  min-height: 0vh;
  transition: all 400ms;
  z-index: 10;
}

.container-about svg {
  position: fixed;
  width: 22px;
  height: 22px;
  margin-top: 20px;
  right: 60px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .container-navbar {
    padding: 20px 15px;
  }

  .container-about svg {
    position: fixed;
    width: 22px;
    height: 22px;
    margin-top: 20px;
    right: 40px;
    cursor: pointer;
  }
}

.container-logo {
  width: 120px;
  cursor: pointer;
}
.container-logo img {
  width: 100%;
}
.container-logo h1 {
  display: flex;
  align-items: center;
  height: 22px;
}
.container-right-nav {
  display: flex;
}
.container-donate {
  display: inline;
  margin-right: 15px;
}
.container-donate a {
  display: block;
}
.container-about {
  position: relative;
  margin-right: 20px;
  opacity: 0.8;
  -webkit-transition: opacity 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out;
  -ms-transition: opacity 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
  z-index: 999;
}
.container-about:hover {
  opacity: 1;
}
.container-about h2 {
  opacity: 1;
  user-select: none;
  transition: opacity 100ms;
}
.container-about:after,
.container-about:before {
}

.open-about .container-about {
  position: relative;
  float: right;
  margin-top: 20px;
  margin-right: 40px;
  cursor: pointer;
}
.open-about .container-about:after {
  transform: rotate(45deg);
  width: 3px;
  cursor: pointer;
}
.open-about .container-about:before {
  transform: rotate(-45deg);
  width: 3px;
  cursor: pointer;
}
.open-about .container-about h2 {
  opacity: 0;
}
.container-menu-items {
  display: flex;
}
.menu-item:not(:last-child) {
  margin-right: 15px;
}
.container-about {
  cursor: pointer;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.bloc-1 {
  columns: 1;
  column-gap: 40px;
}

.bloc-2 {
  columns: 1;
  column-gap: 40px;
}

.bloc-2-cn {
  columns: 1;
  column-gap: 40px;
}

.bloc-3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.bloc-3 p {
}

.subtitle-about {
  max-width: 80%;
}

.no-max-width {
  max-width: 100% !important;
}

@media only screen and (min-width: 960px) {
  .container-about svg {
    right: 60px;
  }

  .bloc-1 {
    columns: 2;
    column-gap: 40px;
  }

  .bloc-2 {
    columns: 3;
    column-gap: 40px;
  }

  .bloc-2-cn {
    columns: 4;
    column-gap: 40px;
  }

  .navbar-right {
    font-size: 16px !important;
    margin-top: 0px;
  }

  .bloc-3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

.navbar-right {
  display: flex;
  font-size: 12px;
  margin-top: 10px;
}

.embed {
  margin-bottom: 40px;
}
.image-element-class {
  margin-right: 20px;
}
