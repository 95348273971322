* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Monument";
  src: url("./fonts/MonumentExtended-Black.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Anchos";
  src: url("./fonts/AnchoGX.ttf") format("truetype");
}
@font-face {
  font-family: "Reason";
  src: url("./fonts/NewReason-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Reason";
  src: url("./fonts/NewReason-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Reason";
  src: url("./fonts/NewReason-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Reason";
  src: url("./fonts/NewReason-Bold.ttf") format("truetype");
  font-weight: 700;
}
h1 {
  font-family: "Reason";
  text-transform: uppercase;
  font-size: 1.9em;
  padding-bottom: 10px;
}
h2,
li {
  list-style: none;
  font-family: "Reason";
  padding-bottom: 10px;
}
a {
  text-decoration: underline;
  color: inherit;
  font-weight: 400;
}
body {
  background-color: black;
  font-family: "Monument";
  color: white;
}
*::selection {
  background-color: black;
  color: white;
}

.container-about {
  font-size: 0.8em;
  text-align: right;
}
.content-about-container {
  height: calc(100vh - 140px);

  width: 100%;
  background-color: rgba(255, 255, 255, 0.94);
  color: black;
  z-index: 2;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: scroll;
  border-radius: 5px;
}
.display-content .content-about-container {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}
.open-about ~ .content-about-container {
  transform: translateY(0%);
  transition: transform 300ms ease-in-out;
}

.content-about {
  position: relative;
  font-family: sans-serif;
  line-height: 1.5em;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  /*transform: translate(calc(50vw - 50%), calc(50vh - 50%));*/
  left: 0;
  margin: 20px 40px 20px 20px;
}

@media (max-width: 500px) {
  .content-about-container {
    height: calc(100vh - 225px);
  }
  .content-about {
    margin: 10px 10px 10px 10px;
    padding: 20px 5px 20px 5px;
  }

  .content-about-container {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.content-about p {
  font-family: "Reason";
  font-weight: 400;
  font-size: 0.9em;
}
.content-about b {
  font-weight: 700;
}

@media (max-width: 500px) {
  .content-about p {
    font-size: 0.8em;
  }
}

.footer {
  position: fixed;
  bottom: 20px;
  left: 30px;
}

.footer a {
  margin-right: 20px;
}

.footer-link {
  font-size: 12px;

  margin-bottom: 20px;
  position: relative;
  /* flex: auto; */
  top: -6px;
}
