html,
body,
iframe {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.video-background {
  pointer-events: none;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

.header-home {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.container-header-vid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container-header-vid video {
  /* height: 100%;
	min-width: auto; */
  min-width: 100vw;
  opacity: 0.6;
}

.counter-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  width: 400px;
  opacity: 1;
  transition: opacity 300ms;
}
.counter-container.open-about {
  opacity: 0;
}
.counter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.counter-item span:first-child,
.separator {
  font-size: 3em;
}
.before-live {
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  bottom: 20vh;
  font-size: 1.4em;
  opacity: 1;
  letter-spacing: 2px;
  transition: opacity 300ms;
}
.before-live.open-about {
  opacity: 0;
}

/* content live */
.container-header-live {
  position: relative;
}
.container-header-live video {
  width: 100%;
}
#twitch-embed {
  background: #000000;
  box-shadow: 20px 20px 60px #151515, -20px -20px 60px #2b2b2b;
}
.live-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  border-radius: 3px;
  padding: 3px 6px;
  color: white;
  font-size: 0.8em;
  text-transform: uppercase;
  display: none;
}
.texture-bg-live {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.texture-bg-live.bg-grain {
  opacity: 0.4;
}
.texture-bg-live.bg-capture {
  opacity: 0.08;
  filter: blur(4px);
}
@media (max-width: 500px) {
  .counter-container {
    flex-direction: column;
    max-width: 400px;
    width: inherit;
  }
  .counter-item {
    margin: 15px 0;
  }
  .counter-item span:first-child {
    font-size: 2.3em;
  }
  .separator {
    display: none;
  }
  .before-live {
    bottom: 40px;
    font-size: 1.1em;
  }
}

.credits-intro {
  font-family: "Reason";
}

.start-button {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.start-button:hover {
  background-color: #f6f6f6;
}
.start-button:active {
  position: relative;
  top: 1px;
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro-txt {
  margin-left: 40px;
  margin-right: 40px;
  max-width: 800px;
  font-size: 16px;
}

@media (max-height: 700px) {
  .intro-txt {
    font-size: 12px;
  }

  .start-button {
    font-size: 10px;
  }
}
